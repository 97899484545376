import PropTypes from 'prop-types'
import React from 'react'
import Emailjs from 'emailjs-com'
import { StaticImage } from 'gatsby-plugin-image'
import { ToastContainer, toast } from 'react-tiny-toast'
import {
  FaAngular,
  FaBootstrap,
  FaCss3,
  FaFacebook,
  FaGitAlt,
  FaGithub,
  FaHtml5,
  FaInstagram,
  FaJs,
  FaLaravel,
  FaLinkedin,
  FaNodeJs,
  FaReact,
  FaSass,
  FaTwitter,
} from 'react-icons/fa'
import { DiJqueryLogo } from 'react-icons/di'
import { IoLogoIonic } from 'react-icons/io'

class Main extends React.Component {
  state = {
    name: '',
    email: '',
    message: '',
  }

  handleSubmit(e) {
    e.preventDefault()
    const { name, email, message } = this.state
    let templateParams = {
      from_name: name,
      email: email,
      message_html: message,
      to_name: 'Benni',
    }
    Emailjs.send(
      'gmail',
      'template_9N0wErP9',
      templateParams,
      'user_D6tkVQV2jfABRcJDyVZid',
    ).then(
      function (response) {
        toast.show(
          <div>
            Success, Thank you for contact me. I will reply asap
          </div>,
          {
            position: 'top-center',
            timeout: 3000,
            pause: false,
            delay: 0,
            className: 'custom-react-tiny-toast',
          },
        )
      },
      function (error) {
        toast.show(<div>Something Wrong. Please try again</div>, {
          position: 'top-center',
          timeout: 3000,
          pause: false,
          delay: 0,
          className: 'custom-react-tiny-toast',
        })
      },
    )
    this.resetForm()
  }

  resetForm() {
    this.setState({
      name: '',
      email: '',
      message: '',
    })
  }

  handleChange = (param, e) => {
    this.setState({ [param]: e.target.value })
  }

  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
        role="presentation"
      ></div>
    )
    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={
          this.props.timeout
            ? { display: 'flex' }
            : { display: 'none' }
        }
      >
        <article
          id="skill"
          className={`${
            this.props.article === 'skill' ? 'active' : ''
          } ${this.props.articleTimeout ? 'timeout' : ''}`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Skill</h2>
          <div className="showcase-container stack">
            <div className="icon-stack">
              <FaReact /> ReactJS
            </div>
            <div className="icon-stack">
              <FaAngular /> Angular
            </div>
            <div className="icon-stack">
              <IoLogoIonic /> Ionic
            </div>
            <div className="icon-stack">
              <FaNodeJs /> NodeJS
            </div>
            <div className="icon-stack">
              <FaJs /> Javascript
            </div>
            <div className="icon-stack">
              <FaLaravel /> Laravel
            </div>
            <div className="icon-stack">
              <DiJqueryLogo /> JQuery
            </div>
            <div className="icon-stack">
              <FaHtml5 /> HTML5
            </div>
            <div className="icon-stack">
              <FaCss3 /> CSS3
            </div>
            <div className="icon-stack">
              <FaSass /> SASS
            </div>
            <div className="icon-stack">
              <FaBootstrap /> Bootstrap
            </div>
            <div className="icon-stack">
              <FaGitAlt /> Git
            </div>
          </div>
          {close}
        </article>

        <article
          id="education"
          className={`${
            this.props.article === 'education' ? 'active' : ''
          } ${this.props.articleTimeout ? 'timeout' : ''}`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Education</h2>
          <p> Maha Bodhi Elementary (2004 - 2010)</p>
          <p> Maha Bodhi Junior High School (2010 - 2013)</p>
          <p> Maha Bodhi Senior High School (2013 - 2016)</p>
          <p>
            Bina Nusantara University | Computer Science (2016 - 2020)
          </p>
          {close}
        </article>

        <article
          id="experience"
          className={`${
            this.props.article === 'experience' ? 'active' : ''
          } ${this.props.articleTimeout ? 'timeout' : ''}`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Experience</h2>
          <h3>
            Frontend Engineer - PT.Solusi Sinergi Digital / Surge
            (August 2020 - Present)
          </h3>
          <ul className="row">
            <li>
              Developing and maintaining Content Management System
              (CMS) for Linipoin App, Trivia Panel with modern
              front-end framework (Angular)
            </li>
            <li>
              Developing and maintaining simple quiz for Trivia using
              modern front-end framework (Angular)
            </li>
            <li>Maintaining Adakita web app (ReactJS)</li>
            <li>
              Developing and maintaining Linipoin and Sahabi App
              (Ionic)
            </li>
            <li>
              Implementing the front end web application based on the
              UI design
            </li>
            <li>
              Ensuring the web application is compatible across
              different browser platform
            </li>
            <li>
              Proficient with REST API integration on the front end
            </li>
          </ul>
          <h3>
            Frontend Engineer - PT.Raksasa Laju Lintang / Ralali
            (March 2019 - March 2020)
          </h3>
          <ul className="row">
            <li>
              Implemented the front end web application based on the
              UI/UX design
            </li>
            <li>
              Worked with UI/UX designer to serve the best experience
              for the user with understanding the potential
              improvement or technical feasibility of UI/UX design
            </li>
            <li>
              Optimized the web application for performance and
              scalability
            </li>
            <li>
              Ensured the web application is compatible across
              different browser platform
            </li>
            <li>
              Participated in requirements, design and code review
            </li>
            <li>
              1 year experience using modern front-end framework such
              as ReactJS
            </li>
            <li>
              Proficient with REST API integration on the front end
            </li>
            <li>
              Knowledge of debugging website issues on different
              browser, desktop and mobile devices
            </li>
            <li>
              Familiar with Unit Testing (Jest & Cypress), SCM (Git)
              and Scrum
            </li>
          </ul>
          {close}
        </article>

        <article
          id="showcase"
          className={`${
            this.props.article === 'showcase' ? 'active' : ''
          } ${this.props.articleTimeout ? 'timeout' : ''}`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Showcase</h2>
          <div className="project">
            <h3 className="project-name">
              <a href="https://www.bennibennibenni.com">
                My Personal Website
              </a>
            </h3>
            <StaticImage
              src="../assets/images/personal-website.webp"
              alt="A dinosaur"
              imgStyle={{ borderRadius: '4px' }}
            />

            <ul className="row-project">
              <li>Built with GatsbyJS</li>
              <li>Blazing fast loading times</li>
              <li>Custom 404 page</li>
              <li>Mobile Friendly</li>
              <li>Component seperated code</li>
              <li>Google Analytics</li>
              <li>Progressive Web Apps / PWA</li>
            </ul>
          </div>

          <div className="project">
            <h3 className="project-name">
              {/* <a href="https://www.bennibennibenni.com">Story Life</a> */}
              Story Life
            </h3>
            <span>
              <StaticImage
                src="../assets/images/story-life.webp"
                alt="A dinosaur"
                imgStyle={{ borderRadius: '4px' }}
              />
            </span>
            <ul className="row-project">
              <li>Built with Angular 10</li>
              <li>CSS framework using PaperCSS</li>
              <li>API from Dummyapi.io</li>
              <li>Progressive Web Apps / PWA</li>
            </ul>
          </div>

          <div className="project">
            <h3 className="project-name">
              <a href="https://chit-chat-omega.vercel.app/">
                Chit-Chat
              </a>
            </h3>
            <span>
              <StaticImage
                src="../assets/images/chit-chat.webp"
                alt="A dinosaur"
                imgStyle={{ borderRadius: '4px' }}
              />
            </span>
            <ul className="row-project">
              <li>Built with React library</li>
              <li>Custom component</li>
              <li>Websocket using socket.io</li>
            </ul>
          </div>

          <div className="project">
            <h3 className="project-name">
              <a href="https://bennibennibenni.github.io/covid-case-tracker/">
                Covid Case Tracker
              </a>
            </h3>
            <span>
              <StaticImage
                src="../assets/images/covid-case-tracker.webp"
                alt="A dinosaur"
                imgStyle={{ borderRadius: '4px' }}
              />
            </span>
            <ul className="row-project">
              <li>ES6</li>
              <li>Custom Element</li>
              <li>CSS framework using Bootstrap</li>
              <li>Webpack as module bundler</li>

              <li>Eslint for linting</li>
              <li>AJAX</li>
              <li>Mobile Friendly</li>
            </ul>
          </div>

          <div className="project">
            <h3 className="project-name">
              <a href="https://bennibennibenni.github.io/personal-website-vanilla-js/">
                Personal Website
              </a>
            </h3>
            <span>
              <StaticImage
                src="../assets/images/vanilla-website.webp"
                alt="A dinosaur"
                imgStyle={{ borderRadius: '4px' }}
              />
            </span>
            <ul className="row-project">
              <li>Vanilla Javascript</li>
              <li>Semantic HTML</li>
              <li>Mobile Friendly</li>
            </ul>
          </div>

          <div className="project">
            <h3 className="project-name">
              <a href="https://bennibennibenni.github.io/bookshelf-webstorage/">
                Bookshelf
              </a>
            </h3>
            <span>
              <StaticImage
                src="../assets/images/bookshelf-webstorage.webp"
                alt="A dinosaur"
                imgStyle={{ borderRadius: '4px' }}
              />
            </span>
            <ul className="row-project">
              <li>Vanilla Javascript</li>
              <li>Web Storage</li>
              <li>Mobile Friendly</li>
            </ul>
          </div>

          <div className="project">
            <h3 className="project-name">
              <a href="https://cuan-calc.vercel.app">
                Cuan Calculator
              </a>
            </h3>
            <span>
              <StaticImage
                src="../assets/images/cuan-cal.webp"
                alt="A dinosaur"
                imgStyle={{ borderRadius: '4px' }}
              />
            </span>
            <ul className="row-project">
              <li>Build with GatsbyJS</li>
              <li>Mobile Responsive</li>
              <li>Custom component using Styled Component</li>
            </ul>
          </div>
          {close}
        </article>

        <article
          id="contact"
          className={`${
            this.props.article === 'contact' ? 'active' : ''
          } ${this.props.articleTimeout ? 'timeout' : ''}`}
          style={{ display: 'none' }}
        >
          <ToastContainer />

          <h2 className="major">Contact</h2>
          <form
            method="post"
            onSubmit={this.handleSubmit.bind(this)}
            onReset={this.resetForm.bind(this)}
          >
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                id="name"
                required
                value={this.state.name}
                onChange={this.handleChange.bind(this, 'name')}
              />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                id="email"
                value={this.state.email}
                required
                onChange={this.handleChange.bind(this, 'email')}
              />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea
                name="message"
                id="message"
                required
                value={this.state.message}
                onChange={this.handleChange.bind(this, 'message')}
                rows="4"
              ></textarea>
            </div>
            <ul className="actions">
              <li>
                <input
                  type="submit"
                  value="Send Message"
                  className="special"
                />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a href="https://www.linkedin.com/in/benni-xie-039145176/">
                <FaLinkedin />
              </a>
            </li>
            <li>
              <a href="https://github.com/bennibennibenni">
                <FaGithub />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/BenniXie">
                <FaFacebook />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/benni_xie/">
                <FaInstagram />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/BenniXie">
                <FaTwitter />
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
