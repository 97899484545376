import PropTypes from 'prop-types'
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const Header = (props) => (
  <header
    id="header"
    style={props.timeout ? { display: 'none' } : {}}
  >
    <StaticImage
      src="../assets/images/profile.webp"
      alt="Benni Profile"
      placeholder="blurred"
      layout="fixed"
      width={150}
      height={150}
    />
    <div className="content">
      <div className="inner">
        <h1>Benni</h1>
        <p>
          I am a punctual and motivated individual who is able to work
          in a busy environment and produce high standard of work. In
          additional, i am also an excellent team worker and able to
          take instructions from all levels and build up good working
          relationships with all colleagues. Therefore, I have ability
          to manage multiple responsibilities to the highest standard.
        </p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('skill')
            }}
          >
            Skill
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('education')
            }}
          >
            Education
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('experience')
            }}
          >
            Experience
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('showcase')
            }}
          >
            Showcase
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contact Me
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
